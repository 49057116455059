import React, { useEffect, useMemo, useRef, useState } from 'react';
import IconSwitch from './IconSwitch';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { ChevronUpIcon } from '@heroicons/react/outline';
import { useMap } from 'react-leaflet';
import { useDebounce } from 'react-use';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const legendItems = [
  {
    icon: 'terrain_evalue',
    label: 'Terrain évalué',
    html: /* HTML */ `<span class="whitespace-nowrap">Terrain évalué</span>`,
  },
  {
    icon: 'programme_selectionnee',
    label: 'Sélectionnés pour l’étude',
    html: /* HTML */ `<span class="whitespace-nowrap">Sélectionnés</span
      ><br /><span class="whitespace-nowrap">pour l’étude</span>`,
  },
  {
    icon: 'programme_en_cours',
    label: 'Prog. en cours de commercialisation',
    html: /* HTML */ `<span class="whitespace-nowrap">Prog. en cours</span
      ><br /><span class="whitespace-nowrap">de commercialisation</span>`,
  },
  {
    icon: 'programme_termines_2019',
    label: `Commercialisation terminée post-${new Date().getFullYear() - 3}`,
    html: /* HTML */ `<span class="whitespace-nowrap"
        >Commercialisation terminée</span
      ><br /><span class="whitespace-nowrap"
        >post-${new Date().getFullYear() - 3}</span
      >`,
  },
  {
    icon: 'permis_de_construire',
    label: `PC > 5 logements accordés depuis ${new Date().getFullYear() - 1}`,
    html: /* HTML */ `<span class="whitespace-nowrap"
        >PC > 5 logements accordés</span
      ><br /><span class="whitespace-nowrap"
        >accordés depuis ${new Date().getFullYear() - 1}</span
      >`,
  },
];

const clusterLegend = [
  {
    className: '',
    text: 'Plusieurs programmes à proximité',
    html: /* HTML */ `<span class="whitespace-nowrap">Plusieurs programmes</span
      ><br /><span class="whitespace-nowrap">à proximité</span>`,
  },
  {
    className: 'custom-cluster-marker-has-selelected-program',
    text: 'Plusieurs programmes à proximité (dont sélection)',
    html: /* HTML */ `<span class="whitespace-nowrap">Plusieurs programmes</span
      ><br /><span class="whitespace-nowrap"
        >à proximité (dont sélection)</span
      >`,
  },
];

const MyToolTip = ({ children, content, className }) => {
  return (
    <div className={className}>
      <div>{children}</div>
      <div className="my-content">
        <div className="relative">
          <span className="arrow" />
          <div className="block">{content}</div>
        </div>
      </div>
    </div>
  );
};

const MyToolTipStyled = styled(MyToolTip)`
  display: inline-block;
  position: relative;

  .my-content {
    display: none;
    top: calc(100% + 4px);
    left: 50%;
    position: absolute;
    z-index: 1;
    transform: translateX(-50%);
    background-color: white;
    padding: 8px;
    font-size: 12px;
    border-radius: 8px;
    box-shadow: 9px 11px 18px -13px rgba(0, 0, 0, 0.75);
    border: 1px solid #00000047;
  }

  .arrow {
    position: absolute;
    bottom: calc(100% + 4px);
    left: 50%;
    width: 8px;
    height: 8px;
    border-color: #0000001e;
    border-left: 1px solid;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-bottom: 1px solid;
    transform: translate(-50%, 0) rotate(135deg);
    background: white;
  }

  &:hover {
    .my-content {
      display: inline-block !important;
    }
  }
`;

export const ClusterLegends = ({ isPdfMode }) => {
  return (
    <>
      {clusterLegend.map((item, index) => (
        <div key={index} className={'legendItems flex flex-row items-center'}>
          <div className={classNames('custom-cluster-marker', item.className)}>
            <span className="deco"></span>
            <span className="label">2</span>
          </div>
          <div
            className={classNames('p-1 xl:p-2 ', {
              'text-tiny 2xl:text-xs': !isPdfMode,
              'text-sm': isPdfMode,
            })}
          >
            {item.text}
          </div>
        </div>
      ))}
    </>
  );
};

const LegendDropDown = ({
  items,
  noNeedMarker,
  indexStudiesParcel,
  map,
  programmesNeufs,
  onlySelected,
}) => {
  const [openWithPopup, setOpenWithPopup] = useState(true);
  const [openState, setOpenState] = useState(true);
  const dropdownBtnRef = useRef(null);
  const { isPdfMode } = useSelector((state) => state.others);
  const { errorCarIsochrone } = useSelector((state) => state.marketSurvey);

  useDebounce(
    () => {
      setOpenState(openWithPopup);
    },
    500,
    [openWithPopup]
  );

  useEffect(() => {
    var openPopup = false;
    var closed = false;
    if (!isPdfMode) {
      map.on('movestart', () => {
        !openPopup && !closed && setOpenState(false);
      });
      map.on('moveend', () => {
        !openPopup && !closed && setOpenState(true);
      });
      map.on('zoomstart', () => {
        !openPopup && !closed && setOpenState(false);
      });
      map.on('zoomend', () => {
        !openPopup && !closed && setOpenState(true);
      });
      map.on('popupopen', () => {
        setOpenWithPopup(false);
        openPopup = true;
      });
      map.on('popupclose', () => {
        setOpenWithPopup(true);
        openPopup = false;
      });

      if (dropdownBtnRef.current) {
        dropdownBtnRef.current.addEventListener('click', () => {
          closed = !closed;
        });
      }
    }
  }, [map]);

  const handleOpen = () => {
    setOpenState((state) => !state);
  };

  const legends = useMemo(() => {
    if (programmesNeufs) {
      return items.filter((item) => item.icon !== 'programme_termines_2019');
    }
    if (onlySelected) {
      return items.filter((item) =>
        ['terrain_evalue', 'programme_selectionnee'].includes(item.icon)
      );
    }
    return items;
  }, [items, programmesNeufs, onlySelected]);

  return (
    <div>
      <div
        ref={dropdownBtnRef}
        onClick={handleOpen}
        className={classNames(
          'flex justify-between items-center cursor-pointer hover:bg-gray-200  pb-1 pt-1 xl:pt-2 px-1 xl:px-2',
          { 'border-b-1 border-gray-500': openState }
        )}
      >
        <span
          className={classNames('font-medium', {
            'text-xxs xl:text-sm': !isPdfMode,
            'text-sm': isPdfMode,
          })}
        >
          Légende
        </span>
        {!openState ? (
          <ChevronDownIcon className="h-4 w-4" />
        ) : (
          <ChevronUpIcon className="h-4 w-4" />
        )}
      </div>
      <div
        className={classNames(
          'flex-col',
          { flex: openState },
          { hidden: !openState }
        )}
      >
        {programmesNeufs && !errorCarIsochrone && (
          <div className="flex items-center gap-x-2">
            <span
              className="inline-block w-8 bg-blue-700"
              style={{ height: 3 }}
            />
            <span
              className={classNames('p-1 xl:p-2 ', {
                'text-tiny 2xl:text-xs': !isPdfMode,
                'text-sm': isPdfMode,
              })}
            >
              10 minutes en voiture
            </span>
          </div>
        )}
        {legends.map((item, index) => {
          return (
            <div
              key={`legend-item__${index}`}
              className={'legendItems flex flex-row items-center'}
            >
              {noNeedMarker && index === indexStudiesParcel ? (
                <div className="bg-blue-500 w-6 h-1"></div>
              ) : (
                IconSwitch(item.icon, 'terrain evalue')
              )}
              <div
                className={classNames('p-1 xl:p-2 ', {
                  'text-tiny 2xl:text-xs': !isPdfMode,
                  'text-sm': isPdfMode,
                })}
              >
                {item.label}
              </div>
            </div>
          );
        })}
        {!isPdfMode && <ClusterLegends isPdfMode={isPdfMode} />}
      </div>
    </div>
  );
};

const MarketSurveyLegentV2 = ({
  items,
  programmesNeufs,
  onlySelected,
  noCluster = false,
}) => {
  const { errorCarIsochrone } = useSelector((state) => state.marketSurvey);

  const legends = useMemo(() => {
    if (programmesNeufs) {
      return items.filter((item) => item.icon !== 'programme_termines_2019');
    }
    if (onlySelected) {
      return items.filter((item) =>
        ['terrain_evalue', 'programme_selectionnee'].includes(item.icon)
      );
    }
    return items;
  }, [items, programmesNeufs, onlySelected]);

  return (
    <div className="flex items-center bg-white gap-x-6 px-4">
      <div className="text-sm font-semibold rounded-md">Légende :</div>
      <div className="flex items-center gap-x-3">
        {programmesNeufs && !errorCarIsochrone && (
          <MyToolTipStyled
            content={
              <span
                dangerouslySetInnerHTML={{
                  __html: `15 minutes<br /> en voiture`,
                }}
              />
            }
          >
            <span
              className="inline-block w-8 bg-blue-700"
              style={{ height: 3 }}
            />
          </MyToolTipStyled>
        )}
        {legends.map((item, index) => (
          <MyToolTipStyled
            key={index}
            content={<span dangerouslySetInnerHTML={{ __html: item.html }} />}
          >
            <span className="cursor-pointer">
              {IconSwitch(item.icon, item.label)}
            </span>
          </MyToolTipStyled>
        ))}
        {!noCluster && (
          <>
            {clusterLegend.map((item, index) => (
              <MyToolTipStyled
                key={index}
                content={
                  <span dangerouslySetInnerHTML={{ __html: item.html }} />
                }
              >
                <div className={'legendItems flex flex-row items-center'}>
                  <div
                    className={classNames(
                      'custom-cluster-marker',
                      item.className
                    )}
                  >
                    <span className="deco"></span>
                    <span className="label">2</span>
                  </div>
                </div>
              </MyToolTipStyled>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const MarketSurveyLegendLayer = ({
  noNeedMarker,
  indexStudiesParcel,
  onLoadLegendMarketSurvey,
  programmesNeufs,
  isPdfMode,
  onlySelected,
  noCluster,
}) => {
  const map = useMap();

  useEffect(() => {
    onLoadLegendMarketSurvey &&
      onLoadLegendMarketSurvey(
        isPdfMode ? (
          <LegendDropDown
            onlySelected={onlySelected}
            isPdfMode={isPdfMode}
            items={legendItems}
            indexStudiesParcel={indexStudiesParcel}
            noNeedMarker={noNeedMarker}
            programmesNeufs={programmesNeufs}
            map={map}
          />
        ) : (
          <MarketSurveyLegentV2
            items={legendItems}
            programmesNeufs={programmesNeufs}
            onlySelected={onlySelected}
            noCluster={noCluster}
          />
        )
      );
  }, []);

  return null;
};

export default MarketSurveyLegendLayer;
