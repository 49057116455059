import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import MarketSurveySummary from './MarketSurveys/sections/MarketSurveySummary';
import _ from 'lodash';
import MSTableSection from './MarketSurveys/sections/MSTableSection';
import BannerSection from './MarketSurveys/sections/BannerSection';
import {
  BLUE_THEME,
  COLLECTIVE_HOUSING,
  CSP,
  DARK_BLUE_THEME,
  DEFINITION_BLOCK,
  DEMOGRAPHY_AND_EMPLOYMENT,
  DETAILED_INFORMATION_2,
  DETAILED_INFORMATION_3,
  EDUCATION,
  FIELD_ATTRIBUTES,
  FROM_25_TO_39_YO,
  GRAY_THEME,
  LOCAL_AUTHORITY_HOUSING,
  MEDIAN_INCOME,
  MERGED_ROW_TABLE,
  MOBILITY,
  NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
  NEIGHBORHOOD,
  NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
  NEW_BUYER_TYPE,
  NEW_IN_AREA_CITY_PRICE,
  NEW_ON_SALE,
  NEW_PROGRAMS,
  NEW_RESERVATION_BY_VAT_TYPE,
  OFFER_AVAILABLE,
  OLD_HOUSING_TREND,
  POLITICAL,
  PRICE_CHANGE,
  PRICE_EVOLUTION_2018,
  RENT_PRICE,
  RESERVATION,
  RESUME,
  UNEMPLOYMENT,
  VACANT_HOUSING,
  GREEN_THEME,
  SENIOR,
  PART_1_DEFS,
  TERRAIN_EMPLACEMENT_PARCELLES,
  COLLECTIVE_HOUSING_MAP_1,
  COLLECTIVE_HOUSING_MAP_2,
  NB_LOG_VACANT_MAP,
  EVO_2014_2020_MAP,
  CHOMAGE_VARIATION_TAUX_MAP,
  CHOMAGE_VARIATION_DIFF_MAP,
  REVENU_MEDIAN_ANNUEL_MAP,
  REVENU_VARIATION_2019_2020,
  POPULATION_NB_MAP,
  POPULATION_VARIATION_MAP,
  POPULATION_CADRE_NB_MAP,
  POPULATION_CADRE_EVO_MAP,
  FROM_25_TO_39_YO_NB_MAP,
  FROM_25_TO_39_YO_VARIATION_MAP,
  SENIOR_NB_MAP,
  SENIOR_VARIATION_MAP,
  DEFINITION,
  ANALYSE_CONCURRENTIELLE,
  LOG_MARCHE_IMMO,
  TERRAIN_QUARTIER,
  COMMUNE,
  DEF_AND_CONTACT,
} from '../_helpers/constant';

import { usePrograms } from '../hooks/usePrograms';
import CoverMarketSurveySection from './MarketSurveys/sections/CoverMarketSurveySection';
import LogementSocial from './MarketSurveys/sections/LogementSocial';
import {
  COLOR_EPCI_COMMUNE,
  COLOR_NEUF_VENTILATION,
} from '../constants/marketSurvey.const';
import { useEDM, useEDMStore } from '../_helpers/useGetDataEdm';
import { TitleWithSelect, select_1_4_options } from './MarketSurveyPage';
import { Chart } from 'chart.js';
import ProgramsBlockWithMap from './MarketSurveys/sections/ProgramsBlockWithMap';
import TerrainEmplacementParcelles, {
  NextTerrainEmplacementParcelles,
} from './MarketSurveys/sections/TerrainEmplacementParcelles';
import { getPerPageArray } from '../store/slices/marketSurvey';
import SynopticVisionAndDynamicCommune from './MarketSurveys/sections/SynopticVisionAndDynamicCommune';
import Api from '../api/Api';
import { setupNumberToSummary } from '../hooks/useSectionEDM';
import axios from 'axios';

Chart.defaults.font.size = 18;

const SectionContainer = ({
  children,
  hide = false,
  partName = null,
  notInTemplage = false,
  isNone = false,
  name = null,
  theme,
  surveyId,
}) => {
  const pdfTemplate = useEDMStore((s) => s.pdfTemplate);

  let checkInPdfTemplete = true;

  if (partName && !notInTemplage && !isNone && name && pdfTemplate?.length) {
    const partSummary = pdfTemplate.find((item) => item.name === partName);

    const thereAreCheckedContents = !!partSummary?.content.filter(
      (item) => item.checked
    ).length;

    const isChecked = partSummary?.content?.find(
      (item) => item.name === name
    )?.checked;

    checkInPdfTemplete = thereAreCheckedContents && isChecked;
  }

  return (
    <>
      {!hide && checkInPdfTemplete && (
        <div
          style={{ height: 1240, maxHeight: 1240 }}
          className="page-container flex-col w-full flex justify-between"
        >
          <div
            style={{
              height: 'calc(1240px - 56px)',
              maxHeight: 'calc(1240px - 56px)',
            }}
            className="pt-12 px-12"
          >
            {partName && (
              <BannerSection
                isPdfRenderMode
                surveyID={surveyId}
                theme={theme}
                name={partName}
              />
            )}
            {children}
          </div>
          <div
            style={{ height: 56 }}
            className="w-full flex items-center justify-center font-bold text-base"
          ></div>
        </div>
      )}
    </>
  );
};

const MarketSurveyPDF = ({ part }) => {
  const { survey_id } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isCapemUser = queryParams.get('icu') === 'true';
  const exportJobUUID = queryParams.get('export_job') || null;

  const { activePrograms } = usePrograms();
  const { setPdfTemplate, pdfTemplate, setCurrentSummary } = useEDMStore(
    (state) => state
  );
  const [show3_1, setShow3_1] = useState(false);
  const { isPart1Only, isPart2Only } = useMemo(
    () => ({
      isPart1Only: part === 1,
      isPart2Only: part === 2,
    }),
    [part]
  );

  const {
    states: { fieldIds, msMetaData, dateMs },
    data: {
      data: {
        parcelData,
        resumeProgrammePart1,
        offersDispoDataPart1,
        reservationsPart1,
        reservationsVariationDataPart1,
        programmesSelected2_3Part1,
        programmesNeufDataPart2,
        neufMevDataPart2,
        neufMevLimotropheDataPart2,
        neufPriceByTVADataPart2,
        neufMevLimotrophe12monthDataPart2,
        neufResParTydeAcquereurDataPart2,
        neufResNetteDataPart2,
        ancienAppartementDataPart2,
        marcheLocatifAppartDataPart2,
        collectifHousingDataPart2,
        vacantHousingDataPart2,
        attributesTerrainDataPart3,
        educationDataPart3,
        mobilityDataPart3,
        nearestShopDataPart3,
        synopticVisonAndDynamicCommuneData,
        demographieAndEmploiData,
        revenusMedianeVariationDataPart4,
        chomageTauxVariationDataPart4,
        populationNombreVariationDataPart4,
        populationFocusCadreDataPart4,
        populationFocus25To39DataPart4,
        populationSeniorDataPart4,
        definition,
      },
      infosDetailsSelectedProgrammes,
    },
    methodes: { getInfoDetailsSelectedProgrammesFunc },
    summaryBrut,
    defaultTemplate,
    refs: {
      resumeRef_1,
      resumeRef_2,
      resumeRef_3,
      resumeRef_4,
      resumeRef_5,
      reservation_ref,
      price_change_ref,
      offer_available_ref,
      detailed_information_2_ref,
      detailed_information_3_ref,
      field_attributes_ref,
      parcelles_emplacement_ref,
      neighborhood_ref,
      csp_ref,
      from_25_to_39_yo_ref,
      education_ref,
      nearest_shops_and_public_equipment_ref,
      new_programs_ref,
      mobility_ref,
      new_on_sale_ref,
      new_in_area_city_price_ref,
      new_buyer_type_ref,
      vacant_housing_ref,
      new_reservation_by_vat_type_ref,
      net_reservations_by_epci_municipalities_ref,
      price_evolution_2018_ref,
      old_housing_trend_ref,
      rent_price_ref,
      local_authority_housing_ref,
      collective_housing_ref,
      political_ref,
      demography_and_employment_ref,
      median_income_ref,
      age_ref,
      unemployment_ref,
      definitionsRef,
    },
  } = useEDM(survey_id, { isPdf: true, isPart1Only, isPart2Only });

  // set page number
  useEffect(() => {
    if (infosDetailsSelectedProgrammes?.length && activePrograms?.length) {
      const pages = document.getElementsByClassName('page-container');
      for (let i = 0; i < pages.length; i++) {
        const currentEl = pages[i];
        if (currentEl.children[1]) {
          currentEl.children[1].textContent = `${i + 1} / ${pages.length}`;
        }
      }
    }
  }, [infosDetailsSelectedProgrammes?.length, activePrograms]);

  const parcelData3_1 = useMemo(() => {
    const partName = TERRAIN_QUARTIER;
    const sectionName = TERRAIN_EMPLACEMENT_PARCELLES;
    const part = pdfTemplate?.find((item) => item.name === partName);
    if (part && part.content.find((item) => item.name === sectionName)) {
      setShow3_1(true);
      if (parcelData?.length > 10) {
        return getPerPageArray(24, parcelData.slice(10));
      }
      return [];
    }
    setShow3_1(false);
    return [];
  }, [parcelData, pdfTemplate]);

  useEffect(() => {
    const getExportJobByUUID = async (uuid) => {
      try {
        const { data } = await axios.get(`/v3/export_jobs/${uuid}`);
        if (data && data.parameters?.sections?.length) {
          setPdfTemplate(data.parameters?.sections);
        }
      } catch (e) {
        console.log(e);
      }
    };

    if (exportJobUUID) {
      getExportJobByUUID(exportJobUUID);
    } else {
      setPdfTemplate(defaultTemplate);
    }
  }, [exportJobUUID]);

  useEffect(() => {
    if (pdfTemplate && activePrograms?.length) {
      const showInfoDetailsSelectedProgs = pdfTemplate
        .find((item) => item.name === ANALYSE_CONCURRENTIELLE)
        ?.content.find(
          (item) => item.name === DETAILED_INFORMATION_3 && item.checked
        );
      showInfoDetailsSelectedProgs &&
        getInfoDetailsSelectedProgrammesFunc({ activePrograms });
    }
  }, [pdfTemplate, activePrograms]);

  const { summary, hidePartSummary } = useMemo(() => {
    if (pdfTemplate) {
      const hidePartSummary = [];
      let summary = pdfTemplate
        .filter((item) => !!item.content.filter((el) => el.checked).length)
        .map((templatePart) => {
          const summaryPart = summaryBrut.find(
            (summaryPart) => summaryPart.name === templatePart.name
          );
          const templateContentNames = templatePart.content
            .filter((templateCont) => templateCont.checked)
            .map((k) => k.name);
          return {
            ...summaryPart,
            content: templateContentNames
              .map((name) => {
                const temp = summaryPart.content.find((t) => t.name === name);
                if (temp) {
                  return temp;
                }
                return null;
              })
              .filter((v) => v),
          };
        });
      summary = setupNumberToSummary(summary).map((item) => ({
        ...item,
        content: item.noContent ? [] : item.content,
      }));
      console.log('Template PDF', pdfTemplate);
      console.log('Summary', summary);
      setCurrentSummary(summary);
      return { summary, hidePartSummary };
    }
    return { summary: [], hidePartSummary: [] };
  }, [pdfTemplate, summaryBrut]);

  if (!fieldIds.length || !pdfTemplate) {
    return <></>;
  }

  const sectionContents = [
    // part 1
    {
      name: ANALYSE_CONCURRENTIELLE,
      summary: (
        <SectionContainer
          key="resume-1"
          hide={
            isPart2Only || hidePartSummary.includes(ANALYSE_CONCURRENTIELLE)
          }
          surveyId={survey_id}
          theme={GREEN_THEME}
          partName={ANALYSE_CONCURRENTIELLE}
        >
          <MarketSurveySummary
            refsArray={summary}
            isPdfRenderMode
            theme={GREEN_THEME}
            partName={ANALYSE_CONCURRENTIELLE}
          />
        </SectionContainer>
      ),
      content: [
        {
          name: RESUME,
          render: (
            <SectionContainer
              key="1.1"
              hide={isPart2Only}
              name={RESUME}
              partName={ANALYSE_CONCURRENTIELLE}
              surveyId={survey_id}
              theme={GREEN_THEME}
            >
              <MSTableSection
                ref={resumeRef_1}
                isPdfRenderMode
                partName={ANALYSE_CONCURRENTIELLE}
                surveyId={survey_id}
                commentFieldName={RESUME}
                personalizedContent={
                  <ProgramsBlockWithMap
                    isPdfMode
                    contentData={resumeProgrammePart1}
                  />
                }
              />
            </SectionContainer>
          ),
        },
        {
          name: DETAILED_INFORMATION_3,
          render: (
            <>
              {(infosDetailsSelectedProgrammes || []).map((item, i) => (
                <SectionContainer
                  key={`1.2.${i + 1}`}
                  hide={isPart2Only}
                  isNone
                  surveyId={survey_id}
                  theme={GREEN_THEME}
                  partName={ANALYSE_CONCURRENTIELLE}
                >
                  <MSTableSection
                    isPdfRenderMode={true}
                    ref={detailed_information_3_ref}
                    surveyId={survey_id}
                    data={[
                      {
                        type: MERGED_ROW_TABLE,
                      },
                    ]}
                    infosDetailsSelectedProgrammes={item}
                    commentFieldName={DETAILED_INFORMATION_3}
                    theme={GREEN_THEME}
                    partName={ANALYSE_CONCURRENTIELLE}
                  />
                </SectionContainer>
              ))}
            </>
          ),
        },
        {
          name: OFFER_AVAILABLE,
          render: (
            <SectionContainer
              key="1.3"
              hide={isPart2Only}
              name={OFFER_AVAILABLE}
              partName={ANALYSE_CONCURRENTIELLE}
              surveyId={survey_id}
              theme={GREEN_THEME}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={offer_available_ref}
                surveyId={survey_id}
                data={offersDispoDataPart1}
                commentFieldName={OFFER_AVAILABLE}
                theme={GREEN_THEME}
                part={1}
                partName={ANALYSE_CONCURRENTIELLE}
              />
            </SectionContainer>
          ),
        },
        {
          name: PRICE_CHANGE,
          render: (
            <SectionContainer
              key="1.4"
              hide={isPart2Only}
              name={PRICE_CHANGE}
              partName={ANALYSE_CONCURRENTIELLE}
              surveyId={survey_id}
              theme={GREEN_THEME}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={price_change_ref}
                title={
                  <TitleWithSelect
                    isPdf
                    value={msMetaData?.periode_1_4 || select_1_4_options[2]}
                    nameSection={PRICE_CHANGE}
                    partName={ANALYSE_CONCURRENTIELLE}
                  />
                }
                surveyId={survey_id}
                data={reservationsVariationDataPart1}
                commentFieldName={PRICE_CHANGE}
                theme={GREEN_THEME}
                partName={ANALYSE_CONCURRENTIELLE}
              />
            </SectionContainer>
          ),
        },
        {
          name: RESERVATION,
          render: (
            <SectionContainer
              key="1.5"
              hide={isPart2Only}
              name={RESERVATION}
              partName={ANALYSE_CONCURRENTIELLE}
              surveyId={survey_id}
              theme={GREEN_THEME}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={reservation_ref}
                surveyId={survey_id}
                data={reservationsPart1}
                commentFieldName={RESERVATION}
                theme={GREEN_THEME}
                partName={ANALYSE_CONCURRENTIELLE}
              />
            </SectionContainer>
          ),
        },
        {
          name: DETAILED_INFORMATION_2,
          render: (
            <SectionContainer
              key="1.6"
              hide={isPart2Only}
              name={DETAILED_INFORMATION_2}
              partName={ANALYSE_CONCURRENTIELLE}
              surveyId={survey_id}
              theme={GREEN_THEME}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={detailed_information_2_ref}
                surveyId={survey_id}
                data={programmesSelected2_3Part1}
                commentFieldName={DETAILED_INFORMATION_2}
                theme={GREEN_THEME}
                partName={ANALYSE_CONCURRENTIELLE}
              />
            </SectionContainer>
          ),
        },
      ],
    },
    // part 2
    {
      name: LOG_MARCHE_IMMO,
      summary: (
        <SectionContainer
          key="summary-2"
          hide={isPart1Only || hidePartSummary.includes(LOG_MARCHE_IMMO)}
          surveyId={survey_id}
          theme={DARK_BLUE_THEME}
          partName={LOG_MARCHE_IMMO}
        >
          <MarketSurveySummary
            refsArray={summary}
            isPdfRenderMode
            ref={resumeRef_2}
            theme={DARK_BLUE_THEME}
            partName={LOG_MARCHE_IMMO}
          />
        </SectionContainer>
      ),
      content: [
        {
          name: NEW_PROGRAMS,
          render: (
            <SectionContainer
              key="2.1"
              hide={isPart1Only}
              name={NEW_PROGRAMS}
              surveyId={survey_id}
              theme={DARK_BLUE_THEME}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={new_programs_ref}
                data={programmesNeufDataPart2}
                surveyId={survey_id}
                commentFieldName={NEW_PROGRAMS}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEW_ON_SALE,
          render: (
            <SectionContainer
              key="2.2"
              hide={isPart1Only}
              name={NEW_ON_SALE}
              surveyId={survey_id}
              theme={DARK_BLUE_THEME}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={new_on_sale_ref}
                surveyId={survey_id}
                data={neufMevDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={NEW_ON_SALE}
                coloredTrOptions={COLOR_EPCI_COMMUNE}
                separatedTr={3}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES,
          render: (
            <SectionContainer
              key="2.3"
              hide={isPart1Only}
              name={NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES}
              surveyId={survey_id}
              theme={DARK_BLUE_THEME}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={net_reservations_by_epci_municipalities_ref}
                surveyId={survey_id}
                data={neufMevLimotropheDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={NET_RESERVATIONS_BY_EPCI_MUNICIPALITIES}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: PRICE_EVOLUTION_2018,
          render: (
            <SectionContainer
              key="2.4"
              hide={isPart1Only}
              name={PRICE_EVOLUTION_2018}
              surveyId={survey_id}
              theme={DARK_BLUE_THEME}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={price_evolution_2018_ref}
                surveyId={survey_id}
                data={neufPriceByTVADataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={PRICE_EVOLUTION_2018}
                coloredTrOptions={COLOR_EPCI_COMMUNE}
                separatedTr={3}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEW_IN_AREA_CITY_PRICE,
          render: (
            <SectionContainer
              key="2.5"
              hide={isPart1Only}
              name={NEW_IN_AREA_CITY_PRICE}
              surveyId={survey_id}
              theme={DARK_BLUE_THEME}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={new_in_area_city_price_ref}
                surveyId={survey_id}
                data={neufMevLimotrophe12monthDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={NEW_IN_AREA_CITY_PRICE}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEW_BUYER_TYPE,
          render: (
            <SectionContainer
              key="2.6"
              name={NEW_BUYER_TYPE}
              hide={isPart1Only || isCapemUser}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={new_buyer_type_ref}
                surveyId={survey_id}
                data={neufResParTydeAcquereurDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={NEW_BUYER_TYPE}
                coloredTrOptions={COLOR_NEUF_VENTILATION}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEW_RESERVATION_BY_VAT_TYPE,
          render: (
            <SectionContainer
              key="2.7"
              hide={isPart1Only || isCapemUser}
              name={NEW_RESERVATION_BY_VAT_TYPE}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={new_reservation_by_vat_type_ref}
                surveyId={survey_id}
                data={neufResNetteDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={NEW_RESERVATION_BY_VAT_TYPE}
                coloredTrOptions={COLOR_EPCI_COMMUNE}
                separatedTr={3}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: OLD_HOUSING_TREND,
          render: (
            <SectionContainer
              key="2.8"
              hide={isPart1Only}
              name={OLD_HOUSING_TREND}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={old_housing_trend_ref}
                surveyId={survey_id}
                data={ancienAppartementDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={OLD_HOUSING_TREND}
                coloredTrOptions={COLOR_EPCI_COMMUNE}
                separatedTr={3}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: RENT_PRICE,
          render: (
            <SectionContainer
              key="2.9"
              hide={isPart1Only}
              name={RENT_PRICE}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={rent_price_ref}
                surveyId={survey_id}
                data={marcheLocatifAppartDataPart2}
                theme={DARK_BLUE_THEME}
                commentFieldName={RENT_PRICE}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: LOCAL_AUTHORITY_HOUSING,
          render: (
            <SectionContainer
              key="2.10"
              hide={isPart1Only}
              partName={LOG_MARCHE_IMMO}
              name={LOCAL_AUTHORITY_HOUSING}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={local_authority_housing_ref}
                surveyId={survey_id}
                personalizedContent={
                  <LogementSocial
                    isPdfRenderMode={true}
                    theme={DARK_BLUE_THEME}
                  />
                }
                theme={DARK_BLUE_THEME}
                commentFieldName={LOCAL_AUTHORITY_HOUSING}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: COLLECTIVE_HOUSING,
          render: (
            <SectionContainer
              key="2.11"
              hide={isPart1Only}
              name={COLLECTIVE_HOUSING}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                ref={collective_housing_ref}
                isPdfRenderMode={true}
                surveyId={survey_id}
                data={collectifHousingDataPart2}
                theme={DARK_BLUE_THEME}
                switchModeMapData={{
                  labels: [
                    'Nombre de logements collectifs (appartements) en 2020',
                    'Evolution 2014 - 2020',
                  ],
                  namesMap: [
                    COLLECTIVE_HOUSING_MAP_1,
                    COLLECTIVE_HOUSING_MAP_2,
                  ],
                  name: COLLECTIVE_HOUSING,
                  currentMap: msMetaData
                    ? msMetaData[COLLECTIVE_HOUSING]
                    : 'first',
                }}
                commentFieldName={COLLECTIVE_HOUSING}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
        {
          name: VACANT_HOUSING,
          render: (
            <SectionContainer
              key="2.12"
              hide={isPart1Only}
              name={VACANT_HOUSING}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={LOG_MARCHE_IMMO}
            >
              <MSTableSection
                ref={vacant_housing_ref}
                isPdfRenderMode={true}
                surveyId={survey_id}
                data={vacantHousingDataPart2}
                theme={DARK_BLUE_THEME}
                switchModeMapData={{
                  labels: [
                    'Nombre de logements vacants en 2020',
                    'Evolution 2014 - 2020',
                  ],
                  namesMap: [NB_LOG_VACANT_MAP, EVO_2014_2020_MAP],
                  name: VACANT_HOUSING,
                  currentMap: msMetaData ? msMetaData[VACANT_HOUSING] : 'first',
                }}
                commentFieldName={VACANT_HOUSING}
                partName={LOG_MARCHE_IMMO}
              />
            </SectionContainer>
          ),
        },
      ],
    },
    // part 3
    {
      name: TERRAIN_QUARTIER,
      summary: (
        <SectionContainer
          key="summary-3"
          hide={
            isPart1Only ||
            isPart2Only ||
            hidePartSummary.includes(LOG_MARCHE_IMMO)
          }
          surveyId={survey_id}
          theme={BLUE_THEME}
          partName={TERRAIN_QUARTIER}
        >
          <MarketSurveySummary
            refsArray={summary}
            isPdfRenderMode
            partName={TERRAIN_QUARTIER}
            ref={resumeRef_3}
          />
        </SectionContainer>
      ),
      content: [
        {
          name: TERRAIN_EMPLACEMENT_PARCELLES,
          render: show3_1 && (
            <Fragment key="3.1">
              <SectionContainer
                key="3.1.1"
                hide={isPart1Only || isPart2Only}
                name={TERRAIN_EMPLACEMENT_PARCELLES}
                surveyId={survey_id}
                theme={BLUE_THEME}
                partName={TERRAIN_QUARTIER}
              >
                <MSTableSection
                  ref={parcelles_emplacement_ref}
                  surveyId={survey_id}
                  theme={BLUE_THEME}
                  commentFieldName={TERRAIN_EMPLACEMENT_PARCELLES}
                  personalizedContent={
                    <TerrainEmplacementParcelles
                      parcelData={parcelData}
                      isPdfMode
                    />
                  }
                  partName={TERRAIN_QUARTIER}
                />
              </SectionContainer>
              {parcelData3_1.map((parcelData, index) => (
                <SectionContainer
                  key={`3.1.1.${index + 1}`}
                  hide={isPart1Only || isPart2Only}
                >
                  <MSTableSection
                    ref={parcelles_emplacement_ref}
                    surveyId={survey_id}
                    theme={BLUE_THEME}
                    commentFieldName={TERRAIN_EMPLACEMENT_PARCELLES}
                    personalizedContent={
                      <NextTerrainEmplacementParcelles
                        parcelData={parcelData}
                      />
                    }
                  />
                </SectionContainer>
              ))}
            </Fragment>
          ),
        },
        {
          name: FIELD_ATTRIBUTES,
          render: (
            <SectionContainer
              key="3.2"
              hide={isPart1Only || isPart2Only}
              name={FIELD_ATTRIBUTES}
              surveyId={survey_id}
              theme={BLUE_THEME}
              partName={TERRAIN_QUARTIER}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={field_attributes_ref}
                surveyId={survey_id}
                data={attributesTerrainDataPart3}
                commentFieldName={FIELD_ATTRIBUTES}
                theme={BLUE_THEME}
                partName={TERRAIN_QUARTIER}
              />
            </SectionContainer>
          ),
        },
        {
          name: EDUCATION,
          render: (
            <SectionContainer
              key="3.3"
              hide={isPart1Only || isPart2Only}
              name={EDUCATION}
              surveyId={survey_id}
              theme={BLUE_THEME}
              partName={TERRAIN_QUARTIER}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={education_ref}
                surveyId={survey_id}
                data={educationDataPart3}
                commentFieldName={EDUCATION}
                theme={BLUE_THEME}
                needIsochrone
                eductionMap
                partName={TERRAIN_QUARTIER}
              />
            </SectionContainer>
          ),
        },
        {
          name: MOBILITY,
          render: (
            <SectionContainer
              key="3.4"
              hide={isPart1Only || isPart2Only}
              name={MOBILITY}
              surveyId={survey_id}
              theme={BLUE_THEME}
              partName={TERRAIN_QUARTIER}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={mobility_ref}
                surveyId={survey_id}
                data={mobilityDataPart3}
                commentFieldName={MOBILITY}
                theme={BLUE_THEME}
                needIsochrone
                partName={TERRAIN_QUARTIER}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT,
          render: (
            <SectionContainer
              key="3.5"
              hide={isPart1Only || isPart2Only}
              name={NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT}
              surveyId={survey_id}
              theme={BLUE_THEME}
              partName={TERRAIN_QUARTIER}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={nearest_shops_and_public_equipment_ref}
                surveyId={survey_id}
                data={nearestShopDataPart3}
                commentFieldName={NEAREST_SHOPS_AND_PUBLIC_EQUIPMENT}
                theme={BLUE_THEME}
                needIsochrone
                partName={TERRAIN_QUARTIER}
              />
            </SectionContainer>
          ),
        },
      ],
    },
    // part 4
    {
      name: COMMUNE,
      summary: (
        <SectionContainer
          key="summary-4"
          hide={isPart1Only || isPart2Only || hidePartSummary.includes(COMMUNE)}
          theme={GRAY_THEME}
          surveyId={survey_id}
          partName={COMMUNE}
        >
          <MarketSurveySummary
            refsArray={summary}
            isPdfRenderMode
            partName={COMMUNE}
            ref={resumeRef_4}
            theme={GRAY_THEME}
          />
        </SectionContainer>
      ),
      content: [
        {
          name: POLITICAL,
          render: (
            <SectionContainer
              key="4.1"
              hide={isPart1Only || isPart2Only}
              name={POLITICAL}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={political_ref}
                surveyId={survey_id}
                theme={GRAY_THEME}
                commentFieldName={POLITICAL}
                personalizedContent={
                  <SynopticVisionAndDynamicCommune
                    data={synopticVisonAndDynamicCommuneData}
                  />
                }
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: DEMOGRAPHY_AND_EMPLOYMENT,
          render: (
            <SectionContainer
              key="4.2"
              hide={isPart1Only || isPart2Only}
              name={DEMOGRAPHY_AND_EMPLOYMENT}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode
                ref={demography_and_employment_ref}
                surveyId={survey_id}
                data={demographieAndEmploiData}
                theme={GRAY_THEME}
                personnalisedGrid={{ containerClass: 'grid grid-cols-12' }}
                commentFieldName={DEMOGRAPHY_AND_EMPLOYMENT}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: MEDIAN_INCOME,
          render: (
            <SectionContainer
              key="4.3"
              hide={isPart1Only || isPart2Only}
              name={MEDIAN_INCOME}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={median_income_ref}
                surveyId={survey_id}
                data={revenusMedianeVariationDataPart4}
                theme={GRAY_THEME}
                switchModeMapData={{
                  labels: [
                    'Revenu médian annuel disponible en 2021 (Insee 2024)',
                    'Variation 2020-2021',
                  ],
                  name: MEDIAN_INCOME,
                  namesMap: [
                    REVENU_MEDIAN_ANNUEL_MAP,
                    REVENU_VARIATION_2019_2020,
                  ],
                  currentMap: msMetaData ? msMetaData[MEDIAN_INCOME] : 'first',
                }}
                commentFieldName={MEDIAN_INCOME}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: UNEMPLOYMENT,
          render: (
            <SectionContainer
              key="4.4"
              hide={isPart1Only || isPart2Only}
              name={UNEMPLOYMENT}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={unemployment_ref}
                surveyId={survey_id}
                data={chomageTauxVariationDataPart4}
                theme={GRAY_THEME}
                switchModeMapData={{
                  labels: [
                    'Taux de chômage 2020 (Insee 2023)',
                    'Différence 2020-2014',
                  ],
                  name: UNEMPLOYMENT,
                  namesMap: [
                    CHOMAGE_VARIATION_TAUX_MAP,
                    CHOMAGE_VARIATION_DIFF_MAP,
                  ],
                  currentMap: msMetaData ? msMetaData[UNEMPLOYMENT] : 'first',
                }}
                commentFieldName={UNEMPLOYMENT}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: NEIGHBORHOOD,
          render: (
            <SectionContainer
              key="4.5"
              hide={isPart1Only || isPart2Only}
              name={NEIGHBORHOOD}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={neighborhood_ref}
                surveyId={survey_id}
                data={populationNombreVariationDataPart4}
                switchModeMapData={{
                  labels: ['Population en 2020', 'Evolution 2018 - 2020'],
                  name: NEIGHBORHOOD,
                  currentMap: msMetaData ? msMetaData[NEIGHBORHOOD] : 'first',
                  namesMap: [POPULATION_NB_MAP, POPULATION_VARIATION_MAP],
                }}
                theme={BLUE_THEME}
                commentFieldName={NEIGHBORHOOD}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: CSP,
          render: (
            <SectionContainer
              key="4.6"
              hide={isPart1Only || isPart2Only}
              name={CSP}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={csp_ref}
                surveyId={survey_id}
                data={populationFocusCadreDataPart4}
                switchModeMapData={{
                  labels: ['Population en 2020', 'Evolution 2018 - 2020'],
                  name: CSP,
                  currentMap: msMetaData ? msMetaData[CSP] : 'first',
                  namesMap: [POPULATION_CADRE_NB_MAP, POPULATION_CADRE_EVO_MAP],
                }}
                theme={DARK_BLUE_THEME}
                commentFieldName={CSP}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: FROM_25_TO_39_YO,
          render: (
            <SectionContainer
              key="4.7"
              hide={isPart1Only || isPart2Only}
              name={FROM_25_TO_39_YO}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={from_25_to_39_yo_ref}
                surveyId={survey_id}
                data={populationFocus25To39DataPart4}
                theme={DARK_BLUE_THEME}
                switchModeMapData={{
                  labels: ['Population en 2020', 'Evolution 2018 - 2020'],
                  name: FROM_25_TO_39_YO,
                  currentMap: msMetaData
                    ? msMetaData[FROM_25_TO_39_YO]
                    : 'first',
                  namesMap: [
                    FROM_25_TO_39_YO_NB_MAP,
                    FROM_25_TO_39_YO_VARIATION_MAP,
                  ],
                }}
                commentFieldName={FROM_25_TO_39_YO}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
        {
          name: SENIOR,
          render: (
            <SectionContainer
              key="4.8"
              hide={isPart1Only || isPart2Only}
              name={SENIOR}
              theme={GRAY_THEME}
              surveyId={survey_id}
              partName={COMMUNE}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={age_ref}
                title={'4.8 – POPULATION : FOCUS - SENIORS (+ DE 65 ANS)'}
                surveyId={survey_id}
                data={populationSeniorDataPart4}
                theme={GRAY_THEME}
                switchModeMapData={{
                  labels: [
                    'Part des 65 ans et plus dans la population en 2020 (Insee 2023)',
                    'Variation 2014-2020',
                  ],
                  name: SENIOR,
                  currentMap: msMetaData ? msMetaData[SENIOR] : 'first',
                  namesMap: [SENIOR_NB_MAP, SENIOR_VARIATION_MAP],
                }}
                commentFieldName={SENIOR}
                partName={COMMUNE}
              />
            </SectionContainer>
          ),
        },
      ],
    },
    // part 5
    {
      name: DEF_AND_CONTACT,
      summary: (
        <SectionContainer
          key="summary-5"
          hide={hidePartSummary.includes(DEF_AND_CONTACT)}
          partName={DEF_AND_CONTACT}
          theme={DARK_BLUE_THEME}
          surveyId={survey_id}
        >
          <MarketSurveySummary
            refsArray={summary}
            isPdfRenderMode
            partName={DEF_AND_CONTACT}
            ref={resumeRef_5}
            theme={DARK_BLUE_THEME}
          />
        </SectionContainer>
      ),
      content: [
        {
          name: DEFINITION,
          render: (
            <SectionContainer
              key="5.1"
              name={DEFINITION}
              theme={DARK_BLUE_THEME}
              surveyId={survey_id}
              partName={DEF_AND_CONTACT}
            >
              <MSTableSection
                isPdfRenderMode={true}
                ref={definitionsRef}
                surveyId={survey_id}
                data={
                  isPart1Only
                    ? [{ type: DEFINITION_BLOCK, data: PART_1_DEFS }]
                    : definition
                }
                theme={DARK_BLUE_THEME}
                commentFieldName={DEFINITION}
                partName={DEF_AND_CONTACT}
                noNeedComment
                isSourceAndDefBlock
              />
            </SectionContainer>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <SectionContainer notInTemplage>
        <CoverMarketSurveySection
          isPdfRenderMode
          surveyId={survey_id}
          dateMs={dateMs}
          parcelData={parcelData}
          msMetaData={msMetaData}
        />
      </SectionContainer>

      {pdfTemplate
        .filter((item) => !!item.content.filter((el) => el.checked).length) // show only a part within content is checked
        .map((templatePart) => {
          const part = sectionContents.find(
            (section) => section.name === templatePart.name
          );
          const checkedContentNames = templatePart.content
            .filter((templateContent) => templateContent.checked)
            .map((el) => el.name);
          if (part) {
            return (
              <Fragment key={templatePart.name}>
                {part.summary}
                {checkedContentNames.map((nameContent) => {
                  const content = part.content.find(
                    (content) => content.name === nameContent
                  );
                  if (content) {
                    return (
                      <Fragment key={nameContent}>{content.render}</Fragment>
                    );
                  }
                  return <></>;
                })}
              </Fragment>
            );
          }
          return <></>;
        })}
    </>
  );
};

export default MarketSurveyPDF;
