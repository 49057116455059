import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useEDM } from '../../_helpers/useGetDataEdm';
import { setupNumberToSummary } from '../../hooks/useSectionEDM';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useMaxHeight } from '../../hooks/useMaxHeight';
import { Container, Draggable } from 'react-smooth-dnd';
import slugify from 'slugify';
import {
  Grip,
  PenLine,
  SquareCheckBig,
  Trash2Icon,
  Move,
  CloudUpload,
  HelpCircle,
  SaveIcon,
  DownloadIcon,
} from 'lucide-react';
import classNames from 'classnames';
import Api from '../../api/Api';
import useSWR from 'swr';
import { getFetcher } from '../../api/fetcher';
import Select, { components } from 'react-select';
import { isArray } from 'lodash';
import swal from 'sweetalert';
import { XIcon } from '@heroicons/react/outline';

const helpData = [
  {
    icon: <PenLine className="w-6 h-6" />,
    label: 'Choisissez le nom du modèle de rapport',
  },
  {
    icon: <SquareCheckBig className="w-6 h-6" />,
    label: 'Sélectionnez les items à conserver -> ☑︎',
  },
  {
    icon: <Grip className="w-6 h-6" />,
    label: "Modifiez l'ordre des items avec la fonction drag & drop :",
    items: [
      "en cliquant sur un item vous pouvez le déplacer (au sein d'une même partie)",
      'en cliquant sur une partie vous pouvez la déplacer (par rapport aux autres parties)',
    ],
  },
  {
    icon: <CloudUpload className="w-6 h-6" />,
    label:
      'Sauvegardez votre modèle si vous souhaitez le réutiliser au prochain export. Il vous suffira de le sélectionner.',
  },
];

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

const OptionSelect = ({ onDelete, ...props }) => {
  const handleClickDelete = (id) => {
    onDelete && onDelete(id);
  };

  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <span>{props.label}</span>
        <span
          className="cursor-pointer hover:text-red-700 text-red-500"
          onClick={(e) => {
            e.stopPropagation();
            handleClickDelete(props.value);
          }}
        >
          <Trash2Icon className="w-6 h-6" />
        </span>
      </div>
    </components.Option>
  );
};

const SousMenuDraggable = ({ data, onChange, name }) => {
  const handleChange = (e) => {
    onChange &&
      onChange({
        target: {
          value: data.map((item) => {
            if (item.name === e.target.name) {
              return { ...item, checked: e.target.checked };
            }
            return item;
          }),
          name,
        },
      });
  };

  const handleDrop = (e) => {
    onChange && onChange({ target: { value: applyDrag(data, e), name } });
  };

  return (
    <Container onDrop={handleDrop}>
      {data.map((item) => (
        <Draggable className="px-4 py-1 hover:bg-blue-200" key={item.name}>
          <label
            htmlFor={slugify(item.label)}
            className="flex flex-row items-center space-x-4 cursor-move"
          >
            <input
              className="cursor-pointer"
              type="checkbox"
              id={slugify(item.label)}
              name={slugify(item.name)}
              checked={item.checked}
              onChange={handleChange}
            />
            <div className="flex flex-row gap-x-1 items-center">
              <h3 className="font-normal text-sm">{item.label}</h3>
              <Grip className="w-4 h-4" />
            </div>
          </label>
        </Draggable>
      ))}
    </Container>
  );
};

const ExportPdfModule = () => {
  const { data: templates, mutate } = useSWR(
    '/v3/export_templates',
    getFetcher
  );
  const { survey_id } = useParams();
  const [summaryLocalState, setSummaryLocalState] = useState([]);
  const heightNav = useMaxHeight(false, true);
  const [saveAsDefault, setSaveAsDefault] = useState(false);
  const [nameTemplate, setNameTemplate] = useState('');
  const { summaryBrut } = useEDM(survey_id, { isPdfModuleExport: true });
  const [sent, SetSent] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const edited = useMemo(
    () =>
      !!summaryLocalState.filter(
        (item) =>
          item.content.filter((el) => el).filter((el) => el.checked).length
      ).length,
    [summaryLocalState]
  );
  const optionsSelect = useMemo(
    () =>
      templates?.length
        ? templates.map((item) => ({ label: item.name, value: item.id }))
        : [],
    [templates]
  );
  const disableSave = !edited || sent || (saveAsDefault && !nameTemplate);

  const changeToSelectedTemplate = (selectedTemplate) => {
    if (
      selectedTemplate &&
      isArray(selectedTemplate?.configuration?.sections)
    ) {
      const currentSummary = selectedTemplate.configuration.sections.map(
        (item) => {
          const mainContent = summaryBrut.find((el1) => el1.name === item.name);
          if (mainContent) {
            return {
              ...mainContent,
              content: item.content.map((el2) => {
                const currentContent = mainContent.content.find(
                  (el3) => el3.name === el2.name
                );
                if (currentContent) {
                  return { ...currentContent, checked: el2.checked };
                }
                return null;
              }),
            };
          }
          return null;
        }
      );
      setSummaryLocalState(currentSummary);
    }
  };

  const handleChangeDefaultTemplateSelect = (e) => {
    if (e) {
      setNameTemplate(e.label);
      const selectedTemplate = templates.find((item) => item.id === e.value);
      changeToSelectedTemplate(selectedTemplate);
    } else {
      setDefaultData();
    }
  };

  const handleDragEnd = useCallback((e) => {
    setSummaryLocalState((curr) => applyDrag(curr, e));
  }, []);

  const handleChangeSousTitre = useCallback((e) => {
    setSummaryLocalState((curr) =>
      curr.map((item) => {
        if (item.name === e.target.name) {
          return {
            ...item,
            content: e.target.value.map((el) => ({
              ...el,
              label: item.content.find((e) => e.name === el.name)?.label || '',
            })),
          };
        }
        return item;
      })
    );
  }, []);

  const setDefaultData = () => {
    setSummaryLocalState(
      summaryBrut.map((item) => ({
        ...item,
        content: item.content.map((el) => ({ ...el, checked: false })),
      }))
    );
  };

  const handleExport = async () => {
    setLoadingSave(true);
    if (edited) {
      const config = {
        sections: summaryLocalState.map((item, iMain) => ({
          name: item.name,
          order: iMain + 1,
          content: item.content
            .filter((el) => el)
            .map((el, iCOntent) => ({
              name: el.name,
              order: iCOntent + 1,
              checked: el.checked,
            })),
        })),
      };
      if (saveAsDefault) {
        const data = {
          name: nameTemplate,
          configuration: config,
        };
        try {
          const req = await Api.post('/v3/export_templates/create', data);
          if (req.status !== 201) {
            alert(`ERROR ${req.status} please try again !!!`);
          }
        } catch (e) {
          console.error(e);
        }
      }
      try {
        const req = await Api.post('/v3/export_jobs/create', {
          market_survey_id: survey_id,
          parameters: config,
        });
        if (req.status !== 201) {
          alert(`Erreur, ${req.status} merci de réessayer !`);
        } else {
          setLoadingSave(false);
          SetSent(true);
          swal(
            '',
            "Le rapport vous parviendra par email d'ici quelques minutes. Pensez à vérifier vos emails indésirables.",
            'success'
          ).then(() => {
            window.location.assign(`/market-survey/${survey_id}/detail`);
          });
        }
        setNameTemplate('');
        setSaveAsDefault(false);
        setDefaultData();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDeleteTemplate = (id) => {
    swal('Voulez-vous vraiment supprimer ce modèle ?', {
      buttons: {
        cancel: 'Annuler',
        reset: {
          text: 'Supprimer',
          value: true,
        },
      },
      icon: 'warning',
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        const res = await Api.delete(`/v3/export_templates/${id}`);
        if (res.status === 204 || res.status === 200) {
          swal('', 'Supprimé !', 'success');
          mutate(templates.filter((item) => item.id !== id));
        } else {
          swal('', 'Il y a eu une erreur lors de la suppression.', 'error');
        }
      }
    });
  };

  const handleChangeTitleWithCheckBox = (name, content) => (e) => {
    const checked = e.target.checked;
    handleChangeSousTitre({
      target: {
        name,
        value: content.map((item) => ({
          ...item,
          checked,
        })),
      },
    });
  };

  const handleChangeCheckBoxSaveTemplate = (e) => {
    const checked = e.target.checked;
    setSaveAsDefault(checked);
    if (checked && nameTemplate.length === 0) {
      window.scroll({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setDefaultData();
  }, [summaryBrut]);

  return (
    <div
      style={{ marginTop: heightNav }}
      className="relative w-full p-8 pb-16 flex flex-col gap-y-6"
    >
      <div
        className="md:fixed md:z-50"
        style={{ top: heightNav + 32, right: 32 }}
      >
        <a
          href={`/market-survey/${survey_id}/detail`}
          className="inline-flex  flex-row space-x-2 items-center bg-red-200 rounded-md px-4 py-2"
        >
          <XIcon className="w-6 h-6" />
          <span className="text-base">Revenir à l'étude</span>
        </a>
      </div>
      <div className="w-full flex flex-col space-y-16">
        <div className="grid grid-cols-12 gap-x-8 gap-y-6 ">
          <div className="col-span-12 md:col-span-9 2xl:col-span-5 flex flex-col space-y-2 row-start-1 row-end-1">
            <h1 className="text-3xl font-semibold">
              Personnalisez votre rapport à exporter
            </h1>
            <div className="flex flex-col">
              <label className="font-semibold">
                Choisir un modèle existant
              </label>
              <div className="max-w-lg">
                <Select
                  onChange={handleChangeDefaultTemplateSelect}
                  options={optionsSelect}
                  required={false}
                  placeholder="Séléctionner"
                  components={{
                    Option: (props) => (
                      <OptionSelect
                        {...props}
                        onDelete={handleDeleteTemplate}
                      />
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12 relative 2xl:col-span-5 flex justify-start 2xl:justify-center row-start-2 row-end-3 2xl:row-start-1 2xl:row-end-1">
            <div className="2xl:absolute border-1 max-w-xl border-blue-500 bg-blue-100 rounded-md p-6 flex flex-col space-y-6">
              <h2 className="flex flex-row space-x-2 items-center text-xl font-semibold py-2 px-4 rounded-md bg-blue-400 text-white">
                <HelpCircle className="w-8 h-8" />
                <span>Aide</span>
              </h2>
              <ul className="flex flex-col space-y-4 text-sm">
                {helpData.map(({ icon, label, items }, i1) => (
                  <li key={i1} className="flex flex-row space-x-2 items-start">
                    {!!items?.length ? (
                      <div className="flex flex-col">
                        <span className="flex flex-row space-x-2 items-start">
                          <span>{icon}</span>
                          <span>{label}</span>
                        </span>
                        <ul className="list-disc pl-16">
                          {items.map((item, i2) => (
                            <li className="text-xs" key={i2}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <>
                        <span>{icon}</span>
                        <span>{label}</span>
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col">
            <label className="font-medium">
              Nom du modèle{saveAsDefault && '*'}
            </label>
            <div className="flex flex-row items-center space-x-4">
              <input
                type="text"
                name="name_template"
                value={nameTemplate}
                onChange={(e) => {
                  setNameTemplate(e.target.value);
                }}
                className="h-10 rounded-md border-1 border-gray-400 max-w-lg"
              />
              <PenLine className="w-8 h-8" />
            </div>
            {nameTemplate.length === 0 && saveAsDefault && (
              <span className="text-sm text-red-500">Champ obligatoire.</span>
            )}
          </div>
          <Container
            autoScrollEnabled
            onDrop={handleDragEnd}
            style={{ display: 'flex', flexDirection: 'column', rowGap: 26 }}
          >
            {setupNumberToSummary(summaryLocalState).map((item) => {
              const noContentJustTitle = item.noContent;
              const checkAllContents = item.content.filter(
                (item) => item.checked
              ).length;

              return (
                <Draggable
                  key={item.name}
                  className="py-6 2xl:max-w-3xl px-4 hover:bg-gray-200 cursor-move border-1 border-gray-200 rounded-md flex flex-col space-y-6"
                >
                  <label className="flex flex-row items-center space-x-2">
                    {noContentJustTitle && (
                      <input
                        type="checkbox"
                        checked={checkAllContents === item.content.length}
                        onChange={handleChangeTitleWithCheckBox(
                          item.name,
                          item.content
                        )}
                      />
                    )}
                    <h2 className="font-semibold text-base flex items-center flex-row space-x-2 cursor-move">
                      <span>{item.summary.label}</span>
                      <Grip className="w-4 h-4" />
                    </h2>
                  </label>
                  {!noContentJustTitle && (
                    <SousMenuDraggable
                      onChange={handleChangeSousTitre}
                      data={item.content}
                      name={item.name}
                    />
                  )}
                </Draggable>
              );
            })}
          </Container>
          <div className="flex flex-col space-y-6">
            <label
              className={classNames('flex flex-row space-x-3 items-center', {
                'opacity-50 cursor-not-allowed': !edited,
              })}
            >
              <input
                onChange={handleChangeCheckBoxSaveTemplate}
                type="checkbox"
                name="save-as"
                disabled={!edited}
              />
              <span className="font-medium">Sauvegarder ce modèle</span>
              <CloudUpload className="w-8 h-8" />
            </label>
            <div>
              <button
                disabled={disableSave}
                onClick={handleExport}
                className={classNames(
                  'px-8 py-3 inline-flex items-center bg-blue-500 hover:bg-blue-700 text-white rounded-md',
                  {
                    'opacity-50': disableSave,
                  }
                )}
              >
                {loadingSave ? 'Enregistrement en cours...' : 'Exporter le PDF'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ExportPdfModule);
